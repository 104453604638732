.form-group {
    &.required .form-control-label::after {
        content: "*";
        color: $grey-75;
    }
}

.shipping-address-block {
    .form-group {
        margin-bottom: 1.5rem;
    }
}

